'use client'
import React, { FC, useState } from 'react'
import { X } from 'phosphor-react'

const CloseButton = ({ onClick }) => (
  <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
    <button
      onClick={onClick}
      type="button"
      className="flex rounded-md p-2 hover:bg-white hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-white"
    >
      <span className="sr-only">Dismiss</span>
      <X className="h-6 w-6 text-white" aria-hidden="true" />
    </button>
  </div>
)

interface BarWrapperProps {
  children: React.ReactNode
}

const BarWrapper: FC<BarWrapperProps> = ({ children }) => {
  const [show, setShow] = useState(true)

  const closeButton = () => {
    setShow(!show)
  }

  return show ? (
    <div className="relative bg-primary" id="promo-bar">
      {children}
      <CloseButton onClick={closeButton} />
    </div>
  ) : null
}

export default BarWrapper
